<template>
  <v-img
    :src="requireImg()"
    style="position: absolute;top: 0;bottom: 0"
    height="100%"
    width="100%"
  >
    <v-overlay
      :color="color"
      :z-index="zIndex"
      absolute
      :opacity="opacity"
    ></v-overlay>
  </v-img>
</template>

<script>
export default {
  name: "bg",
  data() {
    return {
      images: ["1969.jpg", "5206.jpg", "2471284.jpg", "clients1.jpg"]
    };
  },
  methods: {
    requireImg() {
      return require("@/assets/clients1.jpg");
      /*
      return require("@/assets/lauderlarkin/" +
        this.images[Math.floor(Math.random() * this.images.length)]);
       */
    }
  },
  props: {
    color: {
      type: String,
      default: "primary"
    },
    opacity: {
      type: String,
      default: ".5"
    },
    zIndex: {
      type: Number,
      default: 0
    }
  }
};
</script>

<style scoped></style>
